import fetchDefault from './fetchDefaults.js'

export default {
    namespaced: true,

    actions: {
        /**
         * Get portraits for user profile.
         */
        async getPortraits() {
            const res = await fetchDefault.get('portraits')
            if (res.status === 200) {
                const data = await res.json()
                return data
            }
        },
        /**
         * Save selected portrait.
         */
        async savePortrait(context, portrait) {
            const res = await fetchDefault.post('portrait', { portrait })
            return res.status === 200
        },
        /**
         * Get user's best scores.
         */
        async getBestScores() {
            const res = await fetchDefault.get('user-scores')
            if (res.status === 200) {
                const data = await res.json()
                return data
            }
        },
        /**
         * Get user's favorite topics.
         */
        async getFavoriteTopics() {
            const res = await fetchDefault.get('favorite-topics')
            if (res.status === 200) {
                const data = await res.json()
                return data
            }
        },
        /**
         * Update user's login.
         */
        async updateLogin({ dispatch }, payload) {
            try {
                const res = await fetchDefault.post('update-login', payload)
                const data = await res.json()
                if (res.status === 200) {
                    const token = data.token
                    sessionStorage.setItem('token', token)
                    await dispatch('auth/getUser', null, { root: true })
                }
                delete data.token
                return { ...data }
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Update user's email.
         */
        async updateEmail(context, payload) {
            try {
                const res = await fetchDefault.post('update-email', payload)
                const data = await res.json()
                return data
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Validate email update.
         */
        async validateEmail(context, payload) {
            try {
                const res = await fetchDefault.post('validate-email', payload)
                const data = await res.json()
                return data
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Update user's password.
         */
        async updatePassword(context, payload) {
            try {
                const res = await fetchDefault.post('update-password', payload)
                const data = await res.json()
                return data
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Update newsletter setting.
         */
        async updateNewsletter(context, payload) {
            try {
                const res = await fetchDefault.post('update-newsletter', payload)
                const data = await res.json()
                return data
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Remove user's account.
         */
        async removeUser({ dispatch }) {
            try {
                const res = await fetchDefault.post('remove-user')
                if (res.status === 200) {
                    dispatch('auth/logOut', null, { root: true })
                    return true
                }
            } catch (err) {
                return false
            }
        },
    },
}
