// https://next.vuex.vuejs.org/guide/plugins.html#committing-mutations-inside-plugins

export default function createSocketPlugin(socket) {
    return (store) => {
        ///socket.onAny((event, ...args) => console.log(event, ...args))

        // standard events
        socket.on('connect', () => store.commit('auth/setConnected', true))
        socket.on('disconnect', () => {
            store.commit('auth/setConnected', false)
            store.commit('auth/setNamed', false)
        })

        // lobby events
        socket.on('NB_GAMES', (payload) => store.commit('lobby/setNbGames', payload))
        socket.on('WAITING_PLAYERS', (payload) => store.commit('lobby/setWaitingPlayers', payload))

        // challenge events
        socket.on('CHALLENGE', (payload) => store.commit('challenges/setChallenge', payload))

        // game events
        socket.on('START_GAME', (payload) => store.commit('game/setGameId', payload))
        socket.on('OPPONENT_READY', () => store.commit('game/setOpponentReady'))
        socket.on('GO', (payload) => store.commit('game/loadGame', payload))
        socket.on('NEXT_TURN', (payload) => store.dispatch('game/nextTurn', payload))

        socket.on('UPDATE_RESOURCES', (payload) => store.commit('game/updateResources', payload))
        socket.on('UPDATE_ENERGY', (payload) => store.commit('game/updateEnergy', payload))
        socket.on('UPDATE_FROZEN', (payload) => store.commit('game/updateFrozen', payload))
        socket.on('UPDATE_POINTS', (payload) => store.commit('game/updatePoints', payload))
        socket.on('UPDATE_FLAMMES', (payload) => store.commit('game/updateFlammes', payload))
        socket.on('UPDATE_SAPHIRS', (payload) => store.commit('game/updateSaphirs', payload))
        socket.on('UPGRADE_ARMOR', (payload) => store.commit('game/upgradeArmor', payload))
        socket.on('UPDATE_EXTERNAL', (payload) => store.commit('game/updateExternal', payload))
        socket.on('UPDATE_XP', (payload) => store.commit('auth/updateXp', payload))

        socket.on('ENABLE_CARDS', (payload) => store.commit('game/enableCards', payload))
        socket.on('ENABLE_TECH', (payload) => store.commit('game/enableTech', payload))
        socket.on('ENABLE_ENGAGE0', (payload) => store.commit('game/enableEngage0', payload))

        socket.on('REBUILD_DRAW', (payload) => store.commit('game/rebuildDraw', payload))
        socket.on('REVEAL_DRAW', (payload) => store.commit('game/revealDraw', payload))
    }
}
