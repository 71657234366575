import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueGtag from 'vue-gtag'

import './registerServiceWorker.js'

import './assets/main.scss'
import './assets/fontello.css'
import './assets/portraits-60.css'

const app = createApp(App)
app.use(router)
    .use(store)
    .use(VueGtag, {
        config: { id: 'G-CPXBX9NB7X' },
    })
    .mount('#app')

import 'drag-drop-touch'
