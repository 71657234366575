import { Tooltip } from 'bootstrap'
import gsap from 'gsap'

import { RANKS, KEYWORDS } from './global.js'
import CardsData from '../../data/json/cards_data.json'

export default {
    methods: {
        /**
         * ...
         */
        formatDatetime(dt, time = false) {
            const d = new Date(dt)
            const txt =
                'le ' +
                ('0' + d.getDate()).slice(-2) +
                '/' +
                ('0' + (d.getMonth() + 1)).slice(-2) +
                '/' +
                d.getFullYear() +
                (time ? ' à ' + ('0' + d.getHours()).slice(-2) + 'h' + ('0' + d.getMinutes()).slice(-2) : '')
            return txt
        },
        formatDesc(txt, small = false) {
            const w1 = small ? 10 : 16
            const h1 = small ? 10 : 16
            const w2 = 12
            const h2 = 16

            /// TODO: https://github.com/cure53/DOMPurify

            txt = txt
                .replace(/\n/g, '<br>')
                .replace(/\*(.+?)\*/g, '<em>$1</em>')
                .replace(/\__(.+?)\__/g, '<strong>$1</strong>')
                .replace(/`(.+?)`/g, '<em class="text-muted"><span class="icon-quote-left"></span>$1<span class="icon-quote-right"></em></span>')
                .replace(/(\d?[+-]?\d)?@force/g, `<span class="stat force" title="Force">$1<img src="/img/force.webp" width="${w1}" height="${h1}" class="stat" alt="Force" ></span>`)
                .replace(/(\+?\d)?@cosmos/g, `<span class="stat cosmos" title="Cosmos">$1<img src="/img/cosmos.webp" width="${w1}" height="${h1}" class="stat" alt="Cosmos"></span>`)
                .replace(/(\+?\d)?@soin/g, `<span class="stat heal" title="Soin">$1<img src="/img/heal.webp" width="${w1}" height="${h1}" class="stat" alt="Soin"></span>`)
                .replace(/(\+?\d)?@energie/g, '<span class="stat energy" title="Energie">$1<span class="icon-flash" alt="Energie"></span></span>')
                .replace(/(@tres_commun|@vert)/g, `<img src="/img/rank1.webp" width="${w1}" height="${h1}" class="rank" alt="${RANKS[1]}" title="${RANKS[1]}">`)
                .replace(/(@commun|@orange)/g, `<img src="/img/rank2.webp" width="${w1}" height="${h1}" class="rank" alt="${RANKS[2]}" title="${RANKS[2]}">`)
                .replace(/(@peu_commun|@argente)/g, `<img src="/img/rank3.webp" width="${w1}" height="${h1}" class="rank" alt="${RANKS[3]}" title="${RANKS[3]}">`)
                .replace(/(@rare|@dore)/g, `<img src="/img/rank4.webp" width="${w1}" height="${h1}" class="rank" alt="${RANKS[4]}" title="${RANKS[4]}">`)
                .replace(/(@tres_rare|@bleu)/g, `<img src="/img/rank5.webp" width="${w1}" height="${h1}" class="rank" alt="${RANKS[5]}" title="${RANKS[5]}">`)
                .replace(/@flamme/g, `<img src="/img/flamme.webp" width="${w2}" height="${h2}" class="stat" alt="Flamme" title="Flamme">`)
                .replace(/@saphir/g, `<img src="/img/saphir.webp" width="${w2}" height="${h2}" class="stat" alt="Saphir" title="Saphir">`)
                .replace(/@sablier/g, '<span class="icon-hourglass" alt="Sablier" title="Sablier"></span>')

            for (const [label, keyword] of Object.entries(KEYWORDS)) {
                const re = new RegExp('@' + keyword, 'g')
                txt = txt.replace(re, `<span class="keyword ${keyword}">${label}</span>`)
            }

            return txt
        },
        formatMessage(txt) {
            txt = txt
                .replace(/@carte\(([-\w]+?)\)/g, (whole, cid) => {
                    if (CardsData[cid]) {
                        return `<span class="view" data-id="${cid}" data-toggle="modal" data-target="#card-modal">${CardsData[cid].name}</span>`
                    } else {
                        return cid
                    }
                })
                .replace(/@lien\((.+?)\)/g, '<a href="$1" class="newtab">$1</a>')
                .replace(/@img\((.+?)\)/g, '<img src="$1" style="max-width:100%">')

            return this.formatDesc(txt)
        },
        isHorizontal(cardId) {
            return cardId !== null && cardId.match(/^(armure|robe|ecaille|anneau|balmung|urne|trident)/) !== null
        },
        isArmor(cardId) {
            return cardId !== null && cardId.match(/^(armure|robe)/) !== null
        },
        getCardEffect(effect) {
            return (
                '<div class="effect' +
                (effect.type ? ' ' + effect.type : '') +
                '">' +
                (effect.keyword ? '<em class="keyword ' + KEYWORDS[effect.keyword] + '">' + effect.keyword + '</em> - ' : '') +
                this.formatDesc(effect.result) +
                '</div>'
            )
        },
        initExtLinks(scope = null, ask = false) {
            document.querySelectorAll((scope ? scope + ' ' : '') + 'a.newtab').forEach(function (elt) {
                elt.addEventListener('click', function (evt) {
                    evt.preventDefault()
                    if (!ask || (ask && confirm('Ouvrir dans un nouvel onglet ?'))) {
                        window.open(this.href)
                    }
                })
            })
        },
        initCardsTooltips() {
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('.view'))
            tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new Tooltip(tooltipTriggerEl, {
                    container: 'body',
                    offset: '-5px, 5px',
                    trigger: 'hover', // avoid display on focus back after modal closing
                    placement: 'right',
                    html: true,
                    template: '<div class="tooltip card-view" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
                    title: function () {
                        return (
                            '<img src="/img/cards/' + tooltipTriggerEl.dataset.id + '.webp"' + (tooltipTriggerEl.dataset.id.match(/^(armure|robe|anneau|balmung)/) ? ' class="horizontal"' : '') + '>'
                        )
                    },
                })
            })
        },
        initGoTop() {
            const goTop = document.getElementById('gotop')
            window.addEventListener('scroll', function () {
                if (window.scrollY > 0) {
                    goTop.style.display = 'block'
                } else {
                    goTop.style.display = 'none'
                }
            })
        },
        scrollTop() {
            window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' })
        },
        /**
         * Game
         */
        prevCard(event) {
            const container = event.target.closest('.card-container')
            const elements = container.getElementsByClassName('card-placeholder')
            if (elements.length > 1) {
                const firstElement = elements[0]
                const lastElement = elements[elements.length - 1]

                firstElement.style.transitionDuration = '300ms'
                firstElement.style.transform = container.classList.contains('horizontal') ? 'translateY(-40px)' : 'translate(40px)'

                setTimeout(() => {
                    lastElement.after(firstElement)
                    setTimeout(() => {
                        const transform = ['translate(0px)']
                        if (firstElement.classList.contains('rotate-right')) {
                            transform.unshift('rotate(90deg)')
                        } else if (firstElement.classList.contains('rotate-left')) {
                            transform.unshift('rotate(-90deg)')
                        }
                        firstElement.style.transform = transform.join(' ')
                    }, 100)
                }, 300)
            }
        },
        nextCard(event) {
            const container = event.target.closest('.card-container')
            const elements = container.getElementsByClassName('card-placeholder')
            if (elements.length > 1) {
                const firstElement = elements[0]
                const lastElement = elements[elements.length - 1]

                lastElement.style.transitionDuration = '300ms'
                lastElement.style.transform = container.classList.contains('horizontal') ? 'translateY(-40px)' : 'translate(40px)'

                setTimeout(() => {
                    container.insertBefore(lastElement, firstElement)
                    setTimeout(() => {
                        const transform = ['translate(0px)']
                        if (lastElement.classList.contains('rotate-right')) {
                            transform.unshift('rotate(90deg)')
                        } else if (lastElement.classList.contains('rotate-left')) {
                            transform.unshift('rotate(-90deg)')
                        }
                        lastElement.style.transform = transform.join(' ')
                    }, 100)
                }, 300)
            }
        },
        /**
         * DnD - NB: require dragInfo / board / $socket / moveCard from component
         */
        dragOver(event) {
            event.preventDefault()
            if (!this.dragInfo.armor || this.dragInfo.equipped) {
                const dropElement = event.target.closest('.droppable')
                const boardIndex = parseInt(dropElement.dataset.name.replace('engage', '')) - 1
                if (this.board[boardIndex] !== null) {
                    dropElement.classList.add('highlight')
                }
            }
        },
        dragLeave(event) {
            const dropElement = event.target.closest('.droppable')
            dropElement.classList.remove('highlight')
        },
        drop(event) {
            event.preventDefault()
            if (!this.dragInfo.armor || this.dragInfo.equipped) {
                const dropElement = event.target.closest('.droppable')
                const boardIndex = parseInt(dropElement.dataset.name.replace('engage', '')) - 1
                if (this.board[boardIndex] !== null) {
                    const data = JSON.parse(event.dataTransfer.getData('application/json'))
                    dropElement.classList.remove('highlight')

                    this.$socket.emit('ENGAGE', { source: data.source, cardUid: data.uid, target: dropElement.dataset.name })
                    this.moveCard({
                        from: { me: true, source: data.source, cardUid: data.uid, cardId: data.id },
                        anim: null,
                        to: { me: true, target: dropElement.dataset.name },
                    })
                }
            }
        },
        /**
         * Send AQUIRE event - NB: require $socket from component
         */
        acquireCard(index, resource) {
            if (!this.isReplay) {
                const card = index > 0 ? this.board[index - 1] : this.boardDiscard[this.boardDiscard.length - 1]
                if (card !== null) {
                    const resourceElement = document.getElementsByClassName('actions').item(index).getElementsByClassName(resource).item(0)
                    const val = parseInt(resourceElement.textContent)
                    if (val > 0) {
                        this.$socket.emit('ACQUIRE', { cardUid: card.uid, resource })
                    }
                }
            }
        },
        /**
         * Common management for transitions leave. Use GSAP to run animation.
         * https://vuejs.org/guide/built-ins/transition.html#javascript-hooks
         * https://greensock.com/get-started/
         */
        animateCardMove(elt, done) {
            const container = elt.closest('.anim-source')
            const source = container.dataset.name
            ///console.log('animateCardMove', source)
            if (this.$store.state.game.animBuffer[source]) {
                const animation = this.$store.state.game.animBuffer[source].anim
                if (animation) {
                    gsap.to(elt, {
                        zIndex: 99,
                        duration: animation.delay || 0.8,
                        delay: 0,
                        x: animation.x,
                        y: animation.y,
                        onComplete: () => {
                            this.$store.dispatch('game/finishMove', source)
                            done()
                        },
                    })
                } else {
                    this.$store.dispatch('game/finishMove', source)
                    done()
                }
            } else {
                done()
            }
        },
    },
}
