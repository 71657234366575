<template>
    <div id="login-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialogLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">Authentification</h3>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <form id="login-form" @submit.prevent="signIn">
                        <div class="input-group mb-3">
                            <span class="input-group-text"><span class="icon-user"></span></span>
                            <input type="text" id="login-login" v-model="login" class="form-control" placeholder="Nom d'utilisateur" required />
                        </div>
                        <div class="input-group mb-3">
                            <span class="input-group-text"><span class="icon-lock"></span></span>
                            <input type="password" id="login-password" v-model="password" class="form-control" placeholder="Mot de passe" required />
                        </div>

                        <div :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1' + (!alert ? ' invisible' : '')" role="alert">
                            {{ alert ? alert.message : '&nbsp;' }}
                        </div>

                        <div class="d-flex flex-column align-items-center mb-3">
                            <button type="submit" class="btn btn-primary mb-2" :disabled="loading">Se connecter</button>
                            <div>
                                <input type="checkbox" id="stay-connected" v-model="remember" class="form-check-input" checked />
                                <label class="form-check-label ps-1" for="stay-connected">Rester connecté</label>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer justify-content-center">
                    <a id="register" href="#" data-bs-toggle="modal" data-bs-target="#register-modal">Créer un compte</a> -
                    <a id="lost-password" href="#" data-bs-toggle="modal" data-bs-target="#lost-password-modal">Mot de passe perdu</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Modal } from 'bootstrap'

export default {
    name: 'LoginModal',
    data: () => ({
        modal: null,
        login: '',
        password: '',
        remember: false,
        loading: false,
        alert: null,
    }),
    computed: {
        ...mapGetters('auth', ['isAuth']),
    },
    methods: {
        ...mapActions('auth', ['logIn']),
        async signIn() {
            this.loading = true
            const ret = await this.logIn({
                login: this.login.trim(),
                password: this.password,
                remember: this.remember,
            })
            this.loading = false
            if (this.isAuth) {
                this.modal.hide()
                if (this.$route.name === 'Forum') {
                    window.location.reload()
                }
            } else {
                this.alert = ret
            }
        },
    },
    mounted() {
        const modalElt = document.getElementById('login-modal')
        this.modal = new Modal(modalElt)
        modalElt.addEventListener('hide.bs.modal', () => {
            this.alert = null
        })
    },
}
</script>
