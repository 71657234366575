<template>
    <div id="lost-password-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialogLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">Mot de passe perdu</h3>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <form id="lost-password-form" @submit.prevent="recoverPassword">
                        <p>Saisissez le pseudo et l'adresse e-mail associés à votre compte pour recevoir un lien de réinitialisation de votre mot de passe.</p>
                        <div class="input-group mb-3">
                            <span class="input-group-text"><span class="icon-user"></span></span>
                            <input type="text" id="lost-password-login" v-model="login" class="form-control" placeholder="Nom d'utilisateur" required />
                        </div>
                        <div class="input-group mb-3">
                            <span class="input-group-text"><span class="icon-mail"></span></span>
                            <input type="email" id="lost-password-email" v-model="email" class="form-control" placeholder="E-mail" required />
                        </div>

                        <div :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1' + (!alert ? ' invisible' : '')" role="alert">
                            {{ alert ? alert.message : '&nbsp;' }}
                        </div>

                        <div class="mb-4 d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary" :disabled="loading">Envoyer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Modal } from 'bootstrap'

export default {
    name: 'LostPasswordModal',
    data: () => ({
        modal: null,
        login: '',
        email: '',
        loading: false,
        alert: null,
    }),
    computed: {
        ...mapGetters('auth', ['isAuth']),
    },
    methods: {
        ...mapActions('auth', ['sendPasswordRecovery']),
        async recoverPassword() {
            this.loading = true
            const ret = await this.sendPasswordRecovery({
                login: this.login.trim(),
                email: this.email.trim(),
            })
            this.alert = ret
            if (this.alert.class === 'success') {
                this.login = ''
                this.email = ''
            }
            this.loading = false
        },
    },
    mounted() {
        const modalElt = document.getElementById('lost-password-modal')
        this.modal = new Modal(modalElt)
        modalElt.addEventListener('hide.bs.modal', () => {
            this.alert = null
        })
    },
}
</script>
