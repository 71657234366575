import socket from '../socket'

export default {
    namespaced: true,
    state: {
        nbGames: 0,
        waitingPlayers: [],
    },
    mutations: {
        setNbGames: (state, nbGames) => {
            state.nbGames = nbGames
        },
        setWaitingPlayers: (state, waitingPlayers) => {
            state.waitingPlayers = waitingPlayers
        },
    },
    actions: {
        getInfos: () => {
            socket.emit('INFOS')
        },
    },
}
