import fetchDefault from './fetchDefaults.js'

export default {
    namespaced: true,
    state: {
        challenge: null,
    },
    mutations: {
        setChallenge: (state, challenge) => {
            state.challenge = challenge
        },
    },
    actions: {
        /**
         * Get challenges details by type.
         */
        async getAllChallenges() {
            const res = await fetchDefault.get('challenges')
            const data = await res.json()
            return data
        },
        /**
         * Get challenges details by type.
         */
        async getBestScores(context, challengeId) {
            const res = await fetchDefault.get(`challenge-scores/${challengeId}`)
            const data = await res.json()
            return data
        },
        /**
         * Send Firebase notification token.
         */
        async sendNotificationToken(context, payload) {
            const res = await fetchDefault.post('add-notification-client', payload)
            return res.status === 200
        },
    },
}
