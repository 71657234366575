const getOptions = () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
        },
    }
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')
    if (token) {
        options.headers.Authorization = 'Bearer ' + token
    }
    return options
}

const get = async (endpoint) => {
    const res = await fetch(`${process.env.VUE_APP_API_URL}/${endpoint}`, { ...getOptions() })
    return res
}

const post = async (endpoint, payload) => {
    const res = await fetch(`${process.env.VUE_APP_API_URL}/${endpoint}`, { ...getOptions(), method: 'POST', body: JSON.stringify(payload) })
    return res
}

export default { getOptions, get, post }
