import socket from '../socket.js'

import { HEROES } from '../global.js'

const getDefaultState = () => {
    return {
        gameId: null,
        isReplay: false,
        hero: null,
        activePlayer: null,
        myView: true,
        // tools
        dragInfo: null, // since dragOver can't access neither dataTransfer nor dragged element
        animBuffer: {}, // handle card move storing data temporary - use source as key (needed for simultaneous animations)
        // board
        nbStash: 0,
        boardDiscard: [],
        board: [],
        enabledEngage0: false,
        engage0: [], // board discard
        engage1: [],
        engage2: [],
        engage3: [],
        engage4: [],
        engage5: [],
        engage6: [],
        engagedResources: [
            { force: 0, cosmos: 0 }, // board discard
            { force: 0, cosmos: 0 },
            { force: 0, cosmos: 0 },
            { force: 0, cosmos: 0 },
            { force: 0, cosmos: 0 },
            { force: 0, cosmos: 0 },
            { force: 0, cosmos: 0 },
        ],
        // player
        hand: [],
        armor: null,
        nbDraw: 0,
        revealedDraw: null,
        energy: null,
        discard: [],
        destroyed: [],
        marked: [],
        external: [],
        // opponent
        opponentReady: false,
        opponentHero: null,
        opponentNbHand: 0,
        opponentArmor: null,
        opponentNbDraw: 0,
        opponentEnergy: 0,
        opponentDiscard: [],
        opponentDestroyed: [],
        opponentMarked: [],
        opponentExternal: [],
        // game status
        points: 0,
        flammes: null,
        saphirs: null,
        turn: 1,
        maxTurns: null,
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        actionLock: (state) => {
            return Object.keys(state.animBuffer).length > 0
        },
    },
    mutations: {
        resetState: (state) => {
            ///console.log('resetState')
            // merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        setGameId: (state, gameId) => {
            state.gameId = gameId
        },
        setHero: (state, hero) => {
            state.hero = hero
        },
        setMyView: (state, bool) => {
            state.myView = bool
        },
        setDragInfo: (state, info) => {
            state.dragInfo = info
        },
        setAnimBuffer: (state, payload) => {
            ///console.log('setAnimBuffer', payload)
            state.animBuffer[payload.animKey] = payload
        },
        unsetAnimBuffer: (state, animKey) => {
            ///console.log('unsetAnimBuffer', animKey)
            delete state.animBuffer[animKey]
        },
        setOpponentReady: (state) => {
            state.opponentReady = true
        },
        /*
        setOpponentNbHand: (state, nb) => {
            state.opponentNbHand = nb
        },
        setOpponentArmor: (state, armor) => {
            state.opponentArmor = armor
        },
        setOpponentNbDraw: (state, nb) => {
            state.opponentNbDraw = nb
        },
        */
        setOpponentDiscard: (state, discard) => {
            state.opponentDiscard = discard
        },
        loadGame: (state, payload) => {
            const {
                hero,
                activePlayer,
                boardDiscard,
                board,
                hand,
                armor,
                nbDraw,
                energy,
                discard,
                destroyed,
                marked,
                external,
                points,
                maxTurns,
                flammes,
                saphirs,
                opponentHero,
                opponentNbHand,
                opponentNbDraw,
                opponentExternal,
                isReplay,
            } = payload
            state.isReplay = isReplay
            state.hero = hero || state.hero
            state.activePlayer = activePlayer
            state.myView = activePlayer
            state.boardDiscard = boardDiscard || state.boardDiscard
            state.board = board
            state.hand = hand
            state.armor = armor || state.armor
            state.nbDraw = nbDraw
            state.energy = energy
            state.usedTech = false
            state.disabledTech = false
            state.discard = discard || state.discard
            state.destroyed = destroyed || state.destroyed
            state.marked = marked || state.marked
            state.external = external
            state.points = points || state.points
            state.maxTurns = maxTurns || state.maxTurns
            state.flammes = flammes
            state.saphirs = saphirs
            state.opponentHero = opponentHero
            state.opponentNbHand = opponentNbHand
            state.opponentNbDraw = opponentNbDraw
            state.opponentExternal = opponentExternal
        },
        removeFrom: (state, payload) => {
            const { me, source, cardUid, cardId, missingEffects } = payload
            ///console.log('removeFrom', source || 'create')
            let card = null
            if (!source) {
                // for added cards, e.g upgraded heroes
                card = { uid: cardUid, id: cardId, missingEffects }
            } else if (source === 'stash') {
                card = { uid: cardUid, id: cardId, missingEffects }
                state.nbStash--
            } else if (source === 'board-discard') {
                const cardIndex = state.boardDiscard.findIndex((c) => c !== null && c.uid === cardUid)
                card = state.boardDiscard.splice(cardIndex, 1)[0]
            } else if (source.startsWith('board')) {
                const cardIndex = state.board.findIndex((c) => c !== null && c.uid === cardUid)
                card = state.board.splice(cardIndex, 1, null)[0]
            } else if (source.startsWith('engage') || ['discard', 'destroyed', 'marked'].includes(source)) {
                const cardIndex = state[source].findIndex((c) => c !== null && c.uid === cardUid)
                card = state[source].splice(cardIndex, 1)[0]
            } else if (source === 'hand') {
                if (me) {
                    const cardIndex = state.hand.findIndex((c) => c !== null && c.uid === cardUid)
                    card = state.hand.splice(cardIndex, 1)[0]
                } else {
                    card = { uid: cardUid, id: cardId, missingEffects }
                    state.opponentNbHand--
                }
            } else if (source === 'armor') {
                if (me) {
                    card = state.armor
                    state.armor = null
                } else {
                    card = state.opponentArmor
                    state.opponentArmor = null
                }
            } else if (source === 'draw') {
                /// TODO: handle empty draw ?
                if (me) {
                    if (state.revealedDraw) {
                        card = state.revealedDraw
                        state.revealedDraw = null
                    } else {
                        card = { uid: cardUid, id: cardId, missingEffects }
                    }
                    state.nbDraw--
                } else {
                    state.opponentNbDraw--
                }
            }

            // set card to be added later into animation buffer
            state.animBuffer[source || 'create'].card = card
        },
        addTo: (state, animKey) => {
            let { me, target, props, resources } = state.animBuffer[animKey].to
            let card = state.animBuffer[animKey].card
            ///console.log('addTo', animKey, card.id, target)
            if (target === 'board-discard') {
                state.boardDiscard.push(card)
            } else if (target.startsWith('board')) {
                const boardIndex = parseInt(target.replace('board', '')) - 1
                state.board[boardIndex] = card
            } else if (target.startsWith('engage')) {
                state[target].push(card)
                if (!me) {
                    // for opponent, resources are specified
                    const engageIndex = parseInt(target.replace('engage', ''))
                    state.engagedResources[engageIndex] = { force: resources.force, cosmos: resources.cosmos }
                }
            } else if (target === 'hand') {
                card.disabled = false // if previously disabled
                if (me) {
                    state.hand.push(card)
                } else {
                    state.opponentNbHand++
                }
            } else if (target === 'armor') {
                if (me) {
                    state.armor = card
                } else {
                    state.opponentArmor = card
                }
            } else if (['discard', 'destroyed', 'marked'].includes(target)) {
                card.disabled = true // if previously enabled for play from discard
                if (target === 'marked') {
                    card = { ...card, ...props }
                }
                if (!me) {
                    target = 'opponent' + target.charAt(0).toUpperCase() + target.slice(1)
                }
                state[target].push(card)
            }
        },
        swapState: (state, payload) => {
            const tmp = state[payload[0]]
            state[payload[0]] = state[payload[1]]
            state[payload[1]] = tmp
        },
        updateResources: (state, { engageIndex, force, cosmos, stats }) => {
            ///console.log('updateResources', { engageIndex, force, cosmos, stats })
            state.engagedResources[engageIndex] = { force, cosmos, stats }
        },
        updateEnergy: (state, { me, energy, disable }) => {
            if (me) {
                state.energy = energy
                if (disable !== undefined) {
                    state.usedTech = disable
                }
            } else {
                state.opponentEnergy = energy
            }
        },
        enableTech: (state, enable) => {
            state.usedTech = !enable
        },
        updateFrozen: (state, cardUid) => {
            state.marked.find((c) => c.uid === cardUid).frozen = 1
        },
        updatePoints: (state, { me, points }) => {
            if (me) {
                state.points = points
            } else {
                state.opponentPoints = points
            }
        },
        updateFlammes: (state, flammes) => {
            state.flammes = flammes
        },
        updateSaphirs: (state, saphirs) => {
            state.saphirs = saphirs
        },
        upgradeArmor: (state, { source, cardUid, cardId }) => {
            if (source === 'hand') {
                state.hand.find((c) => c.uid === cardUid).id = cardId
            } else {
                state.armor.id = cardId
            }
        },
        updateExternal: (state, external) => {
            state.external = external
        },
        enableCards: (state, payload) => {
            for (const { targets, cardId, enable } of payload) {
                ///console.log('enableCards', targets, cardId, enable)
                for (const target of targets) {
                    if (target === 'armor') {
                        if (state.armor && (!cardId || c.id.match(cardId))) {
                            state.armor.disabled = !enable
                        }
                    } else if (target === 'engage') {
                        for (let i = 0; i <= 6; i++) {
                            state[`engage${i}`].map((c) => {
                                if (!cardId || c.id.match(cardId)) {
                                    c.disabled = !enable
                                }
                                return c
                            })
                        }
                    } else if (target === 'tech') {
                        state.disabledTech = !enable
                    } else {
                        state[target].map((c) => {
                            if (!cardId || c.id.match(cardId)) {
                                c.disabled = !enable
                            }
                            return c
                        })
                    }
                }
            }
        },
        enableEngage0: (state, enable) => {
            state.enabledEngage0 = enable
        },
        rebuildDraw: (state, n) => {
            state.animBuffer = {} /// TODO: useless ?
            state.discard = []
            state.nbDraw = n
            socket.emit('CONTINUE', 'rebuildDraw')
        },
        revealDraw: (state, card) => {
            state.revealedDraw = card
        },
        changeTurn: (state) => {
            state.turn++
        },
        applyHeroSwitch: (state, newHero) => {
            const currentHeroBase = `${state.hero}-${HEROES[state.hero][2]}`
            const currentArmorBase = `armure-${HEROES[state.hero][2]}`
            const newHeroBase = `${newHero}-${HEROES[newHero][2]}`
            const newArmorBase = `armure-${HEROES[newHero][2]}`

            state.hero = newHero
            state.hand = state.hand.map((c) => {
                c.id = c.id.replace(currentHeroBase, newHeroBase).replace(currentArmorBase, newArmorBase)
                return c
            })
            if (state.armor) {
                state.armor.id = state.armor.id.replace(currentArmorBase, newArmorBase)
            }
            state.discard = state.discard.map((c) => {
                c.id = c.id.replace(currentHeroBase, newHeroBase).replace(currentArmorBase, newArmorBase)
                return c
            })
            state.destroyed = state.destroyed.map((c) => {
                c.id = c.id.replace(currentHeroBase, newHeroBase).replace(currentArmorBase, newArmorBase)
                return c
            })
            state.marked = state.marked.map((c) => {
                c.id = c.id.replace(currentHeroBase, newHeroBase).replace(currentArmorBase, newArmorBase)
                return c
            })
            state.external = state.external.map((c) => c.replace(currentHeroBase, newHeroBase).replace(currentArmorBase, newArmorBase)) // NB: not objects yet
        },
    },
    actions: {
        setChosenHero: ({ commit }, hero) => {
            commit('setHero', hero)
        },
        switchHero: ({ commit }, newHero) => {
            commit('applyHeroSwitch', newHero)
        },
        changeView: ({ state, commit }) => {
            commit('setMyView', !state.myView)
        },
        moveCard: ({ commit, dispatch }, payload) => {
            ///console.log('moveCard', payload)
            commit('setAnimBuffer', { animKey: payload.from.source || 'create', anim: payload.anim, to: payload.to, callback: payload.callback })
            commit('removeFrom', payload.from)
            if (!payload.from.source) {
                // without source, no leave transition but need to finish move anyway
                dispatch('finishMove', 'create')
            }
        },
        finishMove: ({ state, commit }, animKey) => {
            ///console.log('finishMove', animKey)
            if (state.animBuffer[animKey]) {
                // add moved card to state
                if (state.animBuffer[animKey].to) {
                    // can be undefined for armor on armor (see Tech.vue drop)
                    commit('addTo', animKey)
                }

                // continue
                if (state.animBuffer[animKey].callback) {
                    ///console.log('finishMove callback')
                    state.animBuffer[animKey].callback()
                } else {
                    commit('unsetAnimBuffer', animKey)
                }
            }
        },
        overwriteAnimBuffer: ({ state, commit }, payload) => {
            ///console.log('overwriteAnimBuffer', payload)
            commit('setAnimBuffer', {
                animKey: payload.animKey,
                anim: state.animBuffer[payload.animKey].anim,
                card: state.animBuffer[payload.animKey].card,
                to: payload.to,
                callback: payload.callback,
            })
        },
        clearAnimBuffers: ({ commit }, animSources) => {
            ///console.log('clearAnimBuffers', animSources)
            for (const animKey of animSources) {
                commit('unsetAnimBuffer', animKey)
            }
        },
        swapCards: ({ commit }, payload) => {
            commit('swapState', payload)
        },
        runEngageEffect: (context, { target, cardUid }) => {
            socket.emit('ENGAGE_EFFECT', { target, cardUid })
        },
        addToOpponentDiscard: ({ state, commit }, cardId) => {
            commit('setOpponentDiscard', [...state.opponentDiscard, { id: cardId }])
        },
        dragCardInfo: ({ commit }, info) => {
            commit('setDragInfo', info)
        },
        // tech
        runTech: ({ state }, n) => {
            if (!state.isReplay) {
                socket.emit('RUN_TECH', n)
            }
        },
        // game
        endTurn: () => {
            socket.emit('END_TURN')
        },
        nextTurn: ({ commit }, payload) => {
            commit('changeTurn')
            commit('enableTech', payload.activePlayer)
        },
        sendChoice: (context, cards) => {
            socket.emit('CHOOSE', cards)
        },
        resetGameState: ({ commit }) => {
            socket.emit('QUIT_GAME')
            commit('resetState')
        },
        // replay
        replayNext() {
            socket.emit('REPLAY_NEXT')
        },
    },
}
