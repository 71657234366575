<template>
    <div id="register-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialogLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">Créer un compte</h3>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <form id="register-form" @submit.prevent="registerUser">
                        <p>La création d'un compte vous permettra d'accéder à plus de fonctionnalités : profil, forum, mode défis, meilleurs scores...</p>
                        <div class="row">
                            <label for="register-login" class="col-sm-4 col-form-label">Pseudo <small>(publique)</small></label>
                            <div class="col-sm-8">
                                <input type="text" id="register-login" v-model="login" class="form-control" placeholder="Votre nom d'utilisateur" maxlength="100" required />
                            </div>

                            <label for="register-email" class="col-sm-4 col-form-label">E-mail <small>(privé)</small></label>
                            <div class="col-sm-8">
                                <input type="email" id="register-email" v-model="email" class="form-control" placeholder="Votre adresse e-mail" maxlength="100" required />
                            </div>
                        </div>

                        <div class="row">
                            <label for="register-password" class="col-sm-4 col-form-label">Mot de passe <small>(10+ caractères)</small></label>
                            <div class="col-sm-8">
                                <input type="password" id="register-password" v-model="password" class="form-control" placeholder="Votre mot de passe" maxlength="100" required />
                            </div>

                            <label for="register-confirm" class="col-sm-4 col-form-label">Confirmation</label>
                            <div class="col-sm-8">
                                <input type="password" id="register-confirm" v-model="confirmPassword" class="form-control" placeholder="Confirmation du mot de passe" maxlength="100" required />
                            </div>
                        </div>

                        <div :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-3' + (!alert ? ' invisible' : '')" role="alert">
                            {{ alert ? alert.message : '&nbsp;' }}
                        </div>

                        <div class="mb-4 d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary" :disabled="loading || (alert && alert.class === 'success')">Créer le compte</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Modal } from 'bootstrap'

export default {
    name: 'RegisterModal',
    data: () => ({
        modal: null,
        login: '',
        email: '',
        password: '',
        confirmPassword: '',
        loading: false,
        alert: null,
    }),
    computed: {
        ...mapGetters('auth', ['isAuth']),
    },
    methods: {
        ...mapActions('auth', ['register']),
        async registerUser() {
            if (this.password !== this.confirmPassword) {
                this.alert = { class: 'warning', message: 'Mauvaise confirmation du mot de passe' }
            } else if (this.password.length < 10) {
                this.alert = { class: 'warning', message: 'Mot de passe trop court' }
            } else {
                this.loading = true
                const ret = await this.register({
                    login: this.login.trim(),
                    email: this.email.trim(),
                    password: this.password,
                })
                this.loading = false
                this.alert = ret
            }
        },
    },
    mounted() {
        const modalElt = document.getElementById('register-modal')
        this.modal = new Modal(modalElt)
        modalElt.addEventListener('hide.bs.modal', () => {
            this.alert = null
        })
    },
}
</script>

<style lang="scss" scoped></style>
