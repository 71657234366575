import { createStore } from 'vuex'

import authStore from './auth.js'
import profileStore from './profile.js'
import forumStore from './forum.js'
import challengesStore from './challenges.js'
import lobbyStore from './lobby.js'
import gameStore from './game.js'

import createSocketPlugin from './socketPlugin'
import socket from '../socket'
const socketPlugin = createSocketPlugin(socket)

export default createStore({
    modules: {
        auth: authStore,
        profile: profileStore,
        forum: forumStore,
        challenges: challengesStore,
        lobby: lobbyStore,
        game: gameStore,
    },
    plugins: [socketPlugin],
})
