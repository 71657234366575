<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid p-0">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-content" aria-controls="navbar-content" aria-expanded="false" aria-label="Navigation">
                <span class="icon-menu"></span>
            </button>

            <div id="navbar-content" class="collapse navbar-collapse">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <template v-for="(page, uri) in menu" :key="uri">
                        <template v-if="page.length > 2">
                            <li
                                :class="
                                    'nav-item dropdown' +
                                    (Object.values(page[2])
                                        .map((p) => p[0])
                                        .flat()
                                        .includes(currentRouteName)
                                        ? ' active'
                                        : '')
                                "
                            >
                                <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{ page[1] }}</a>
                                <ul class="dropdown-menu">
                                    <li v-for="(subPage, subUri) in page[2]" :key="subUri">
                                        <!--
                                        <a v-if="gameId !== null" :href="subUri" :class="'dropdown-item' + (currentRouteName === subPage[0][0] ? ' active' : '') + ' newtab'">{{ subPage[1] }}</a>
                                        <router-link v-else :class="'dropdown-item' + (currentRouteName === subPage[0][0] ? ' active' : '')" :to="subUri">{{ subPage[1] }}</router-link>
                                        -->
                                        <router-link :class="'dropdown-item' + (currentRouteName === subPage[0][0] ? ' active' : '')" :to="subUri">{{ subPage[1] }}</router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>
                        <template v-else>
                            <li :class="'nav-item' + (page[0].includes(currentRouteName) ? ' active' : '')">
                                <!--
                                <a v-if="gameId !== null" :href="uri" class="nav-link newtab">{{ page[1] }}</a>
                                <router-link v-else :to="uri" class="nav-link">{{ page[1] }}</router-link>
                                -->
                                <router-link :to="uri" class="nav-link">{{ page[1] }}</router-link>
                            </li>
                        </template>
                    </template>
                </ul>

                <!--
                <a v-if="isAuth && gameId !== null" href="/profil" :class="'adhoc' + (currentRouteName === 'Profile' ? ' active' : '') + ' newtab'" title="Voir le profil"
                    ><span class="icon-user-circle"></span>{{ user.login }}</a
                >
                <router-link v-else-if="isAuth" to="/profil" :class="'adhoc' + (currentRouteName === 'Profile' ? ' active' : '')" title="Voir le profil"
                    ><span class="icon-user-circle"></span>{{ user.login }}</router-link
                >
                -->
                <router-link v-if="isAuth" to="/profil" :class="'adhoc' + (currentRouteName === 'Profile' ? ' active' : '')" title="Voir le profil"
                    ><span class="icon-user-circle"></span>{{ user.login }}</router-link
                >
                <a v-else href="#" class="adhoc" data-bs-toggle="modal" data-bs-target="#login-modal">Se connecter</a>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import Tools from '../tools.js'

export default {
    name: 'Navbar',
    mixins: [Tools],
    data: () => ({
        menu: {
            '/': [['Home'], 'Présentation'],
            '/heros': [['Heroes'], 'Héros'],
            '/cartes': [['Cards'], 'Cartes'],
            '/regles': [['Rules'], 'Règles'],
            '/defis': [
                '',
                'Défis',
                {
                    '/defis': [['ChallengesOverview'], 'Le mode Défis'],
                    '/defis/liste': [['ChallengesList', 'Challenge'], 'Jouer une partie'],
                    '/defis/scores': [['ChallengesScores'], 'Meilleurs scores'],
                    ///'/defis/editeur': [['ChallengeEditor'], 'Editeur de défi'],
                },
            ],
            '/duels': [
                '',
                'Duels',
                {
                    '/duels': [['DuelsOverview'], 'Le mode Duels'],
                    '/duels/salon': [['Match'], 'Rejoindre le salon'],
                },
            ],
            '/forum': [['Forum', 'Topic'], 'Forum'],
            '/liens': [['Links'], 'Liens'],
        },
    }),
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('game', ['gameId']),
        ...mapGetters('auth', ['isAuth']),
        currentRouteName() {
            return this.$route.name
        },
    },
    updated() {
        this.initExtLinks('.navbar', true)
    },
}
</script>

<style lang="scss" scoped>
#app nav {
    background: black;
    font-size: 1.1em;
    font-weight: bold;
    padding: 0;

    a {
        color: #e8bd14;
        font-weight: bold;
        border-right: 1px solid #555;
        padding: 1rem 1.8rem;
    }

    a:hover {
        background: black;
        text-shadow: 0 0 15px yellow;
    }

    .active a,
    .adhoc.active {
        background: #e8bd14;
        color: black;
    }

    .navbar-toggler {
        font-size: 1.8em;
        margin: auto;
    }

    .icon-menu {
        color: #e8bd14;
    }

    .adhoc {
        font-size: 0.9em;
        font-weight: normal;
    }

    .dropdown-menu {
        background: black;
        margin: 0;
        border-radius: 0;
    }

    .active .dropdown-menu a:not(.active) {
        background: black;
        color: #e8bd14;
    }

    .dropdown-toggle::after {
        margin-left: 10px;
    }
}
</style>
