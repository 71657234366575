<template>
    <div class="container p-0">
        <Header />
        <Navbar />
        <router-view />
        <Footer />
        <span id="gotop" class="icon-angle-circled-up" @click="scrollTop()"></span>
        <LoginModal v-if="!isAuth" />
        <RegisterModal v-if="!isAuth" />
        <LostPasswordModal v-if="!isAuth" />
        <Toaster />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Header from '@/components/Header.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import LoginModal from '@/components/LoginModal.vue'
import RegisterModal from '@/components/RegisterModal.vue'
import LostPasswordModal from '@/components/LostPasswordModal.vue'
import Toaster from '@/components/Toaster.vue'
import Tools from './tools.js'

import socket from './socket.js'
///import firebase from './firebase.js' // TODO ? centralize and inject (see ChallengesList.vue too)

export default {
    components: {
        Header,
        Navbar,
        Footer,
        LoginModal,
        RegisterModal,
        LostPasswordModal,
        Toaster,
    },
    provide: {
        $socket: socket,
        ///$firebase: firebase,
    },
    mixins: [Tools],
    computed: {
        ...mapGetters('auth', ['isAuth']),
    },
    mounted() {
        this.initGoTop()
    },
}
</script>

<style lang="scss">
@font-face {
    font-family: 'NeoSansW01';
    src: url('../public/fonts/NeoSansW01.woff2') format('woff2'), url('../public/fonts/NeoSansW01.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

#app {
    background: #222;
    font-family: 'NeoSansW01', sans-serif;
    // /display flex
    min-height: 100vh;

    .container {
        max-width: 1140px; // / TODO: handle 1320px ?
        border-left: 1px solid #555;
        border-right: 1px solid #555;
    }
}

main {
    // /flex-grow 1
    background: #efefef;
}

.padded {
    padding: 2%;
}

#app h2 {
    font-size: 1.3rem;
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px dotted #ccc;

    &.black {
        background-color: #222;
        color: white;
        font-size: 1.1em;
        border: 0;
        margin: 0;
        padding: 0.8rem;
    }

    .text-muted {
        font-size: 0.8rem;
        margin-left: 10px;
    }
}

img.rank,
img.stat {
    vertical-align: middle;
}

.icon-flash {
    color: #ffed00;
    text-shadow: 1px 1px 0 #555;
}

.stat {
    font-weight: bold;
}

.stat.force {
    color: #ef0000;
}

.stat.cosmos {
    color: #037cc1;
}

.stat.heal {
    color: green;
}

.stat.energy {
    color: #ffed00;
    text-shadow: 1px 1px 0 black;
}

.stat.energy-txt {
    color: #d9cb18;
}

.keyword {
    font-weight: bold;
}

.keyword.miseenjeu,
.keyword.arrivee {
    color: #007dc6;
}

.keyword.defausser,
.keyword.blesser,
.keyword.geler,
.keyword.detruire,
.keyword.vaincu {
    color: red;
}

.keyword.sortie {
    color: orange;
}

.keyword.marque {
    color: purple;
}

.keyword.findepartie {
    color: #629732;
}

.keyword.findepopee {
    color: orange;
}

.effect {
    line-height: 1.1;
    padding: 2px;
}

.effect.play {
    background: #efddcd;
    color: #302d29;
}

.effect.passive {
    background: #eee6f9;
    color: #302d29;
}

.effect.ground {
    background: #2f2a22;
    color: white;
}

span.view {
    color: #555;
    border-bottom: 1px dotted #555;
    cursor: default;
}

div.info {
    max-width: 650px;
    text-align: center;
    font-size: 0.9em;
    line-height: 0.9em;
    margin: auto;

    .icon-info-circled {
        font-size: 1.5em;
        vertical-align: middle;
    }
}

#gotop {
    position: fixed;
    bottom: 1%;
    right: 1%;
    color: #777;
    font-size: 3em;
    cursor: pointer;
    display: none;
}

/* Modal */
#app {
    .modal-content {
        background: #eee;
        border: 2px solid black;
        box-shadow: 0 0 10px white;
    }

    .modal-header {
        height: 60px;
        background-color: #222;
        color: white;
        border-bottom: 0;
        padding: 0.8rem;

        .modal-title {
            font-size: 1.4em;

            .keyword {
                font-size: 0.9em;
            }
        }

        .close {
            background: transparent;
            color: white;
            font-size: 2.5em;
            border: none;
        }
    }

    .modal-body {
        padding: 30px 30px 0;
    }
}

/* Tooltips */

.tooltip.card-view.show {
    opacity: 1;
}

.tooltip.card-view .tooltip-inner {
    padding: 0;
}

.tooltip.card-view img {
    position: absolute;
    top: -130px;
    max-width: 300px;
    max-height: 300px;
    border-radius: 10px;
}

.tooltip.card-view img.horizontal {
    width: auto;
}

.tooltip-arrow {
    position: absolute;
    display: block;
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

@media screen and (max-width: 700px) {
    .hidable {
        display: none;
    }
}
</style>
