<template>
    <footer>
        <p>
            Saint-Seiya-DeckBuilding.fr v{{ version }} - Site fan réalisé par <a href="mailto:webmaster@saint-seiya-deckbuilding.fr">Dyrio</a> - Jeu de Maxime Babad et Nicolas Badoux, édité par
            <img src="/img/yoka_logo.webp" width="62" height="40" alt="Yoka by Tsume" />
        </p>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data: () => ({
        version: process.env.VUE_APP_VERSION,
    }),
}
</script>

<style scoped lang="scss">
footer {
    background: black;
    color: #999;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
}
</style>
