import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    {
        path: '/heros',
        name: 'Heroes',
        component: () => import(/* webpackChunkName: "heroes" */ '../views/Heroes.vue'),
    },
    {
        path: '/cartes',
        name: 'Cards',
        component: () => import(/* webpackChunkName: "cards" */ '../views/Cards.vue'),
    },
    {
        path: '/regles',
        name: 'Rules',
        component: () => import(/* webpackChunkName: "rules" */ '../views/Rules.vue'),
    },
    {
        path: '/defis',
        name: 'ChallengesOverview',
        component: () => import(/* webpackChunkName: "challengesoverview" */ '../views/ChallengesOverview.vue'),
    },
    {
        path: '/defis/liste',
        name: 'ChallengesList',
        component: () => import(/* webpackChunkName: "challengeslist" */ '../views/ChallengesList.vue'),
    },
    {
        path: '/defi/:challengeId',
        name: 'Challenge',
        component: () => import(/* webpackChunkName: "challenge" */ '../views/Challenge.vue'),
        meta: {
            auth: true,
            connect: true,
            redirect: 'ChallengesList',
        },
    },
    {
        path: '/defis/scores',
        name: 'ChallengesScores',
        component: () => import(/* webpackChunkName: "challengesscores" */ '../views/ChallengesScores.vue'),
    },
    {
        path: '/replay/:gameId',
        name: 'Replay',
        component: () => import(/* webpackChunkName: "replay" */ '../views/Replay.vue'),
        meta: {
            auth: true,
            connect: true,
            redirect: 'ChallengesList',
        },
    },
    {
        path: '/defis/editeur',
        name: 'ChallengeEditor',
        component: () => import(/* webpackChunkName: "challengeeditor" */ '../views/ChallengeEditor.vue'),
    },
    {
        path: '/duels',
        name: 'DuelsOverview',
        component: () => import(/* webpackChunkName: "duelsoverview" */ '../views/DuelsOverview.vue'),
    },
    {
        path: '/duels/salon',
        name: 'Match',
        component: () => import(/* webpackChunkName: "match" */ '../views/Match.vue'),
    },
    {
        path: '/forum',
        name: 'Forum',
        component: () => import(/* webpackChunkName: "forum" */ '../views/Forum.vue'),
    },
    {
        path: '/forum/tout',
        name: 'ForumAll',
        component: () => import(/* webpackChunkName: "forum" */ '../views/Forum.vue'),
        meta: {
            all: true,
        },
    },
    {
        path: '/forum/:topicId/:page?',
        name: 'Topic',
        component: () => import(/* webpackChunkName: "topic" */ '../views/Topic.vue'),
    },
    {
        path: '/liens',
        name: 'Links',
        component: () => import(/* webpackChunkName: "links" */ '../views/Links.vue'),
    },
    {
        path: '/profil',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
        meta: {
            auth: true,
            redirect: 'Home',
        },
    },
    {
        path: '/validation/:validation',
        name: 'Validation',
        component: () => import(/* webpackChunkName: "validation" */ '../views/Validation.vue'),
    },
    {
        path: '/reset/:reset',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "resetpassword" */ '../views/ResetPassword.vue'),
    },
    {
        path: '/validation-email/:validation',
        name: 'ValidationEmail',
        component: () => import(/* webpackChunkName: "validation" */ '../views/Validation.vue'),
        meta: {
            type: 'email',
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    scrollBehavior(to, from, savedPosition) {
        if (to.hash === '#last') {
            // forum
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({ el: to.hash })
                }, 500)
            })
        }
    },
})

// Initialize user auth then redirect if needed
store.dispatch('auth/initAuth').then(() => {
    router.beforeEach((to, from, next) => {
        const isAuth = store.getters['auth/isAuth']
        const isConnected = store.state.auth.connected
        if ((to.matched.some((route) => route.meta.auth) && !isAuth) || (to.matched.some((route) => route.meta.connect) && !isConnected)) {
            next({ name: to.meta.redirect })
        } else {
            if (from.name === 'Challenge' || from.name === 'Duel') {
                store.dispatch('game/resetGameState')
            }
            next()
        }
    })
})

export default router
