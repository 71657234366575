<template>
    <div id="toaster" class="toast top-0 start-50 translate-middle-x" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
            <strong class="me-auto" v-html="title"></strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body" v-html="message"></div>
    </div>
</template>

<script>
import { Toast } from 'bootstrap'

export default {
    name: 'Toaster',
    data: () => ({
        title: '',
        message: '',
        toast: null,
    }),
    inject: ['$socket'],
    mounted() {
        this.toast = new Toast(document.getElementById('toaster'), { delay: 5000 })

        this.$socket.on('WARNING', ({ title, message, duration }) => {
            this.toast._config.delay = duration || 5000
            this.title = title
            this.message = message
            this.toast.show()
        })
    },
}
</script>

<style scoped lang="scss">
#toaster {
    position: fixed;
    top: 0;
    z-index: 99;
}
</style>