<template>
    <header role="banner">
        <h1>
            <picture>
                <source media="(max-width:399px)" width="400" height="87" srcset="/img/header-400.webp" />
                <source media="(min-width:400px)" width="1138" height="249" srcset="/img/header.webp" />
                <img src="/img/header.webp" alt="Saint Seiya DeckBuilding" />
            </picture>
        </h1>
    </header>
</template>

<script>
export default {
    name: 'Header',
}
</script>

<style lang="scss" scoped>
h1 {
    margin: 0;
}

img {
    width: 100%;
}
</style>
