export const HEROES = {
    seiya: ['Seiya', 'Pégase', 'pegase'],
    shiryu: ['Shiryū', 'Dragon', 'dragon'],
    hyoga: ['Hyōga', 'Cygne', 'cygne'],
    shun: ['Shun', 'Andromède', 'andromede'],
    ikki: ['Ikki', 'Phoenix', 'phoenix'],
}

export const RANKS = {
    1: 'Très Commun (Vert)',
    2: 'Commun (Orange)',
    3: 'Peu Commun (Argenté)',
    4: 'Rare (Doré)',
    5: 'Très Rare (Bleu)',
    9: 'Neutre',
}

export const KEYWORDS = {
    'Mise en jeu': 'miseenjeu',
    Défausser: 'defausser',
    Blesser: 'blesser',
    Geler: 'geler',
    Détruire: 'detruire',
    Arrivée: 'arrivee',
    Sortie: 'sortie',
    Vaincu: 'vaincu',
    Marqué: 'marque',
    'Fin de partie': 'findepartie',
    "Fin d'épopée": 'findepopee',
}

export const TECHNIQUES = {
    seiya: {
        'Comète de Pégase': {
            condition: '@defausser un *Seiya*.',
            effect: 'Ce tour, toutes les cartes du terrain coûtent -2@force *(coût minimum de 1@force)*.',
            faq: 'Cet effet ne concerne que les cartes présentes sur le terrain au moment où la technique est utilisée, pas celles qui peuvent arriver pendant le tour.',
        },
        'Broyeur de Pégase': {
            condition: '@blesser un *Seiya* de votre défausse.',
            effect: 'Un *Seiya* en jeu gagne +2@force.',
        },
        'Météores de Pégase': {
            effect: 'Jouer un *Seiya* @tres_commun depuis votre défausse.',
        },
        'Chevalier de Pégase': {
            condition: '@defausser une carte de votre main.',
            effect: "Utiliser l'effet de votre *Armure de Pégase* en ne détruisant qu'un seul *Seiya* @tres_commun au lieu de deux.",
        },
    },
    shiryu: {
        'Ultime Dragon': {
            condition: '@detruire un *Shiryū* marqué.',
            effect: "Révéler la première carte de la réserve, si c'est un *Personnage* vous l'acquérez blessé, sinon placer la carte dans la défausse du terrain.",
            faq: "Si l'*Ultime Dragon* permet d'acquérir une carte ayant un effet @flamme, @saphir ou @vaincu, l'effet n'est pas activé.",
        },
        Excalibur: {
            condition: '@detruire un *Chevalier* de votre main.',
            effect: 'Un *Shiryū* en jeu gagne +1@force pour chaque *Shiryū* blessé.',
        },
        'Colère du Dragon': {
            condition: '@blesser un *Personnage* @tres_commun de votre main.',
            effect: 'Un *Shiryū* en jeu gagne +2@force.',
        },
        'Chevalier du Dragon': {
            condition: '@defausser une carte de votre main.',
            effect: "Utiliser l'effet de votre *Armure du Dragon* en ne détruisant qu'un seul *Shiryū* @tres_commun au lieu de deux.",
        },
    },
    hyoga: {
        "Exécution de l'Aurore": {
            condition: '@geler une carte de votre main.',
            effect: "Un *Hyōga* en jeu gagne +3@force et +3@cosmos s'il affronte un *Personnage*.",
        },
        'Cercle de Glace': {
            condition: '@defausser une *Armure*.',
            effect: 'Reprendre en main tous vos *Hyōga* gelés.',
        },
        'Poussière de Diamant': {
            effect: 'Un *Hyōga* en jeu gagne +1@cosmos.',
        },
        'Chevalier du Cygne': {
            condition: '@defausser une carte de votre main.',
            effect: "Utiliser l'effet de votre *Armure du Cygne* en ne détruisant qu'un seul *Hyōga* @tres_commun au lieu de deux.",
        },
    },
    shun: {
        'Tempête Nébulaire': {
            effect: 'Retirer deux cartes @tres_commun, @commun ou @peu_commun du terrain puis un *Shun* en jeu gagne +3@cosmos.',
            faq: "Le joueur choisit les deux cartes, qui seront retirées du terrain depuis le dernier emplacement vers le premier. Les cartes ne doivent pas obligatoirement être de même rang. Le bonus de @cosmos peut être appliqué même s'il n'y a pas 2 cartes @tres_commun, @commun ou @peu_commun sur le terrain (on en retire __autant que possible__).",
        },
        'Vague de Tonnerre': {
            effect: "Combattre le *Personnage* du dessus de la défausse du terrain, son coût est réduit de -1@force par *Shun* l'affrontant.",
            faq: 'Le terme "combattre" impose une acquisition par la @force (sauf en combinant avec l\'effet d\'<span class="view" data-id="athena-2">Athéna version Asgard</span>). Si la *Vague de Tonnerre* permet d\'acquérir une carte ayant un effet @flamme, @saphir ou @vaincu, l\'effet n\'est pas activé. La technique s\'applique uniquement à carte du dessus de la défausse du terrain __au moment du déclenchement__ de la *Vague de Tonnerre*.',
        },
        'Défense Tournoyante': {
            effect: 'Un *Personnage* en jeu gagne +2@soin.',
        },
        "Chevalier d'Andromède": {
            condition: '@defausser une carte de votre main.',
            effect: "Utiliser l'effet de votre *Armure d'Andromède* en ne détruisant qu'un seul *Shun* @tres_commun au lieu de deux.",
        },
    },
    ikki: {
        'Envol du Phoenix': {
            effect: 'Un *Ikki* en jeu gagne +3@force face à un *Personnage* ne possédant pas de coût en @cosmos.',
        },
        'Renaissance du Phoenix': {
            condition: '@detruire un de vos *Personnages* marqués.',
            effect: 'Reprendre en main un *Ikki* détruit.',
        },
        'Illusion du Phoenix': {
            effect: 'Retirer une carte @tres_commun, @commun ou @peu_commun du terrain puis compléter le terrain.',
            faq: "L'action de compléter le terrain n'inclut pas celle de décaler les cartes vers la droite.",
        },
        'Chevalier du Phoenix': {
            condition: '@defausser une carte de votre main.',
            effect: "Utiliser l'effet de votre *Armure du Phoenix* en ne détruisant qu'un seul *Ikki* @tres_commun au lieu de deux.",
        },
    },
}

export const EMOTICONS = [
    '😀',
    '😁',
    '😂',
    '🤣',
    '😃',
    '😄',
    '😅',
    '😆',
    '😉',
    '😊',
    '😋',
    '😎',
    '😍',
    '😘',
    '😗',
    '😙',
    '😚',
    '🙂',
    '🤗',
    '🤩',
    '🤔',
    '🤨',
    '😐',
    '😑',
    '😶',
    '🙄',
    '😏',
    '😣',
    '😥',
    '😮',
    '🤐',
    '😯',
    '😪',
    '😫',
    '😴',
    '😌',
    '😛',
    '😜',
    '😝',
    '🤤',
    '😒',
    '😓',
    '😔',
    '😕',
    '🙃',
    '🤑',
    '😲',
    '🙁',
    '😖',
    '😞',
    '😟',
    '😤',
    '😢',
    '😭',
    '😦',
    '😧',
    '😨',
    '😩',
    '🤯',
    '😬',
    '😰',
    '😱',
    '😳',
    '🤪',
    '😵',
    '😡',
    '😠',
    '🤬',
    '😷',
    '🤒',
    '🤕',
    '🤢',
    '🤮',
    '🤧',
    '😇',
    '🤠',
    '🤥',
    '🤫',
    '🤭',
    '🧐',
    '🤓',
    '😈',
    '👿',
    '💪',
    '👈',
    '👉',
    '☝',
    '👇',
    '✌',
    '🤞',
    '🤘',
    '✋',
    '👌',
    '👍',
    '👎',
    '✊',
    '👊',
    '👋',
    '🤟',
    '✍',
    '👏',
    '🙏',
    '🤝',
]
