import fetchDefault from './fetchDefaults.js'
import socket from '../socket'

export default {
    namespaced: true,

    state: {
        user: null, // when auth
        connected: false, // active socket
        named: false, // matched socket with user
    },

    getters: {
        isAuth: (state) => state.user !== null,
    },

    mutations: {
        setUser: (state, user) => {
            state.user = user
        },
        setConnected: (state, connected) => {
            state.connected = connected
        },
        setNamed: (state, named) => {
            state.named = named
        },
        updateXp: (state, { xp, xpProgress }) => {
            state.user.xp = xp
            state.user.xpProgress = xpProgress
        },
    },

    actions: {
        /**
         * Register new user.
         */
        async register(context, payload) {
            const res = await fetchDefault.post('register', payload)
            const data = await res.json()
            return data
        },
        /**
         * Validate user registration.
         */
        async validate({ dispatch }, payload) {
            const res = await fetchDefault.post('validate', payload)
            const data = await res.json()
            if (res.status === 200) {
                const token = data.token
                if (token) {
                    sessionStorage.setItem('token', token)
                    await dispatch('getUser')
                }
            }
            return { class: data.class, message: data.message }
        },
        /**
         * Log user in checking credentials through API and storing returned token.
         */
        async logIn({ dispatch }, payload) {
            const res = await fetchDefault.post('login', payload)
            if (res.status === 200) {
                const token = await res.text()
                if (payload.remember) {
                    localStorage.setItem('token', token)
                } else {
                    sessionStorage.setItem('token', token)
                }
                await dispatch('getUser')
            } else {
                const data = await res.json()
                return data
            }
        },
        /**
         * Log user out clearing stored token and state.
         */
        logOut({ commit }) {
            localStorage.removeItem('token')
            sessionStorage.removeItem('token')
            commit('setUser', null)
        },
        /**
         * Load user automatically from existing stored token.
         */
        async initAuth({ dispatch }) {
            if (localStorage.getItem('token') || sessionStorage.getItem('token')) {
                await dispatch('getUser')
            }
        },
        /**
         * Get user through API and keep it into state.
         */
        async getUser({ commit }) {
            const res = await fetchDefault.get('user')
            if (res.status === 200) {
                const data = await res.json()
                commit('setUser', data)
            }
        },
        /**
         * Send token for user's socket.
         */
        sendToken({ commit }) {
            const options = fetchDefault.getOptions()
            if (options.headers.Authorization) {
                socket.emit('USER', options.headers.Authorization)
                commit('setNamed', true)
            }
        },
        /**
         * Send password recovery email.
         */
        async sendPasswordRecovery(context, payload) {
            const res = await fetchDefault.post('send-password-recovery', payload)
            const data = await res.json()
            return data
        },
        /**
         * Save new password.
         */
        async savePassword(context, payload) {
            const res = await fetchDefault.post('save-password', payload)
            const data = await res.json()
            return data
        },
    },
}
