import fetchDefault from './fetchDefaults.js'

export default {
    namespaced: true,

    actions: {
        /**
         * Get all forum topics.
         */
        async getAllTopics(context, all) {
            const res = await fetchDefault.get(`topics${all ? '/all' : ''}`)
            const data = await res.json()
            return data
        },
        /**
         * Set/unset a topic as favorite.
         */
        async setFavoriteTopic(context, payload) {
            const res = await fetchDefault.post('set-favorite-topic', payload)
            return res.status === 200
        },
        /**
         * Set/unset a topic as read.
         */
        async setReadTopic(context, payload) {
            const res = await fetchDefault.post('set-read-topic', payload)
            return res.status === 200
        },
        /**
         * Add a new topic in the forum.
         */
        async addTopic(context, payload) {
            const res = await fetchDefault.post('add-topic', payload)
            const data = await res.text()
            return data
        },
        /**
         * Get topic title and messages.
         */
        async getTopicData(context, { topicId, page }) {
            const res = await fetchDefault.get(`topic/${topicId}${page ? '/' + page : ''}`)
            const data = await res.json()
            return data
        },
        /**
         * Add a new message in a topic.
         */
        async addMessage(context, payload) {
            await fetchDefault.post('add-message', payload)
        },
        /**
         * Get message source text.
         */
        async getMessage(context, msgId) {
            const res = await fetchDefault.get(`message/${msgId}`)
            const data = await res.text()
            return data
        },
        /**
         * Save modified message.
         */
        async saveMessage(context, payload) {
            await fetchDefault.post('save-message', payload)
        },
    },
}
